<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('knowledge.submissions.overview')" class="item_inbox feedback">
    <CCol cols="3" lg="3" md="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <span><i class="fas fa-question-circle mr-1"/>{{$t('knowledge.Submissions')}} ({{submissions.length}})</span>
        </CCardHeader>
        <CCardBody class="items pt-0 pb-0">
          <CRow class="h-100">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CRow class="item_container">
                <CCol cols="12" lg="12" :class="{ 'pt-0 pb-0' : submissions.length > 0 }">
                  <div v-if="submissions.length > 0">
                    <CRow class="item" 
                          v-for="submission in submissions" 
                          v-bind:key="submission.kb_intent_id_external" 
                          v-bind:class="{active: submission.kb_intent_id_external === knowledgeItem.kb_intent_id_external}" 
                          @click="getKnowledgeItem(submission.kb_intent_id_external);">
                      <CCol cols="12" lg="12">
                        <div class="item_tags mb-2">
                          <b-tag class="detail">{{$t('common.Approve')}}</b-tag>
                        </div>
                        <div v-if="submission.item_title" class="mb-1">
                          <p v-line-clamp="1" class="m-0"><b>{{submission.item_title}}</b></p>
                        </div>
                        <div class="meta d-flex align-items-center">
                          <div class="pr-1">
                            <div v-if="submission.author_user_id_external !== null && submission.author_profile_image !== null" class="profile_image d-inline-block align-middle" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + submission.author_user_id_external + '/' + submission.author_profile_image + '/' + clientToken + ')' }"></div>
                            <userProfileAvatar v-else-if="submission.author" :username="submission.author" :size="20"/>
                          </div>
                          <p v-line-clamp="1" class="mb-0">{{submission.author}} - {{submission.last_update | moment("YYYY-MM-DD HH:mm")}}</p>
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                  <div v-else>
                    <span>{{$t('knowledge.No_submissions_available')}}.</span>
                  </div>                                  
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol cols="6" lg="6" md="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <span>{{ $t('knowledge.Submission_details') }}</span>
        </CCardHeader>
        <CCardBody class="pt-0 pb-0">
          <CRow class="h-100">
            <CCol cols="12" lg="12" class="h-100" v-bind:class="{'pt-0 pb-0' : !knowledgeItem.kb_intent_id_external, 'pr-0 pl-0' : knowledgeItem.kb_intent_id_external}">
              <div v-if="knowledgeItem.kb_intent_id_external" class="h-100 submission_details">
                <knowledgeItem :itemData="knowledgeItem" :showLikes="true" :navigatable="false" :preview="false" :scrollableContent="false"/>
              </div>
              <div v-else>
                <CRow>            
                  <CCol cols="12" lg="12">
                    {{$t('knowledge.Select_submission_to_view_details')}}
                  </CCol>
                </CRow>  
              </div>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter v-if="knowledgeItem.kb_intent_id_external">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div class="d-flex justify-content-lg-end">
                <CButton color="primary"
                         @click="updateSubmissionStatus(knowledgeItem.kb_intent_id_external, 'Inactive')">
                  <i class="fas fa-ban mr-1"/>{{ $t('common.Reject') }}
                </CButton>                
                <CButton v-if="modules.employee_loyalty_programme === 1 && checkPermission('loyalty.grantpoints')"
                         color="primary"
                         @click="openSidebarRight('grant_points');">
                  <i class="fas fa-gift mr-1"/>{{ $t('points.Grant_points') }}
                </CButton>                
                <CButton v-if="checkPermission('knowledge.library.edititem')"
                         color="primary"
                         @click="openSidebarRight('knowledge_item_details', { kb_intent_subcategory: null, kb_intent_id_external: knowledgeItem.kb_intent_id_external, back_to_preview: false })">
                  <i class="fas fa-pen mr-1"/>{{ $t('tooltip.Edit_item') }}
                </CButton>
                <CButton class="m-0"
                         color="primary"
                         :disabled="knowledgeItem.item_content.length === 0"
                         @click="updateSubmissionStatus(knowledgeItem.kb_intent_id_external, 'Active')">
                  <i class="fas fa-check mr-1"/>{{ $t('common.Publish') }}
                </CButton>                              
              </div>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';

import noPermission from '@/components/common/noPermission.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

import knowledgeItem from '@/components/knowledge/knowledgeItem.vue';

export default {
  name: 'Service',
  components: {
    QuickEdit,
    Multiselect,
    noPermission,
    userProfilePopover,
    userProfileAvatar,
    knowledgeItem
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      modules: {
        employee_loyalty_programme: 0
      },
      apiBaseUrl: null,
      clientToken: null,      
      submissions: [],
      knowledgeItem: {},      
      knowledgeItemIdExternal: null,
    }
  },
  methods: {
    getSubmissions() {    
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/submissions')
      .then(res => {
        this.submissions = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateSubmissionStatus(knowledgeItemIdExternal, status) {
      let params = {};      
      params.item_status = status;
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/knowledge/submission/' + knowledgeItemIdExternal, params)
      .then(res => {
        this.getSubmissions();
        this.knowledgeItem = {};
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getKnowledgeItem(knowledgeItemIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/item/' + knowledgeItemIdExternal)
      .then(res => {
        this.knowledgeItem = res.data.data;       
      })
      .catch(err => {
        console.error(err); 
      });       
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;   
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }         
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');  
    // Check the modules
    this.checkModules();
    this.getPlatformPermissions();
    // Get the submissions
    this.getSubmissions();

    this.$bus.$on('update_kb_item', (itemIdExternal) => {
      this.getKnowledgeItem(itemIdExternal)
    });     
  }
}
</script>
